<template>
  <!-- Level 0 -->
  <div v-for="category in categories" :key="category.id">
    <div class="category-header">
      <h2 class="category-header__title">{{ category.name }} ({{ category.productsCount }})</h2>
      <Button
        v-if="usesPremium"
        :buttonClass="$style.percentButton"
        @buttonClick="categoryToEdit = category"
      >
        {{ `${category.percentValue || '-'} %` }}
      </Button>
      <button class="category-header__plus" @click="() => handleAddProductToCategory(category.id)">
        <FontAwesomeIcon :icon="faPlus" />
      </button>
    </div>

    <Product
      v-for="(product, index) in category.products"
      :key="product.id"
      :product="product"
      :percentValue="category.percentValue"
      :usesPremium="usesPremium"
      :last="index === category.products.length - 1"
      @edit-product="editProduct"
    />

    <!-- Level 1 -->
    <div class="child-categories" v-if="category.childCategories.length">
      <div v-for="subCategory in category.childCategories" :key="subCategory.id">
        <div class="category-header">
          <h3 class="category-header__title">
            {{ subCategory.name }} ({{ subCategory.productsCount }})
          </h3>
          <Button
            v-if="usesPremium"
            :buttonClass="$style.percentButton"
            @buttonClick="categoryToEdit = subCategory"
          >
            {{ `${subCategory.percentValue || '-'} %` }}
          </Button>
          <button
            class="category-header__plus"
            @click="() => handleAddProductToCategory(subCategory.id)"
          >
            <FontAwesomeIcon :icon="faPlus" />
          </button>
        </div>

        <Product
          v-for="(product, index) in subCategory.products"
          :key="product.id"
          :product="product"
          :percentValue="subCategory.percentValue"
          :usesPremium="usesPremium"
          :last="index === subCategory.products.length - 1"
          @edit-product="editProduct"
        />

        <!-- Level 2 -->
        <div class="child-child-categories" v-if="subCategory.childCategories.length">
          <div v-for="subSubCategory in subCategory.childCategories" :key="subSubCategory.id">
            <div class="category-header">
              <h4 class="category-header__title">
                {{ subSubCategory.name }} ({{ subSubCategory.productsCount }})
              </h4>
              <Button
                v-if="usesPremium"
                :buttonClass="$style.percentButton"
                @buttonClick="categoryToEdit = subSubCategory"
              >
                {{ `${subSubCategory.percentValue || '-'} %` }}
              </Button>
              <button
                class="category-header__plus"
                @click="() => handleAddProductToCategory(subSubCategory.id)"
              >
                <FontAwesomeIcon :icon="faPlus" />
              </button>
            </div>
            <Product
              v-for="(product, index) in subSubCategory.products"
              :key="product.id"
              :product="product"
              :percentValue="subSubCategory.percentValue"
              :usesPremium="usesPremium"
              :last="index === subSubCategory.products.length - 1"
              @edit-product="editProduct"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <EditCategoryModal
    v-model="categoryToEdit"
    :usesPremium="usesPremium"
    @update="handleCategoryPercentEdit"
    @reset="handleCategoryPercentReset"
  />
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPen, faPercent, faPlus } from '@fortawesome/pro-solid-svg-icons';

import Button from '@/components/common/Button.vue';

import Product from './Product.vue';
import EditCategoryModal from './EditCategoryModal.vue';

export default defineComponent({
  name: 'Categories',
  emits: ['edit-product', 'update-category-percent', 'add-product'],
  components: {
    FontAwesomeIcon,
    Button,
    Product,
    EditCategoryModal,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    usesPremium: {
      type: Boolean,
      required: false,
    },
  },
  setup(_, { emit }) {
    const { t } = useI18n();

    const categoryToEdit = ref(null);

    const editProduct = (id) => emit('edit-product', id);

    const handleCategoryPercentReset = () =>
      emit('update-category-percent', categoryToEdit.value.id);

    const handleCategoryPercentEdit = (value) => {
      const { id, percentValue } = categoryToEdit.value;

      if (value == null) {
        emit('update-category-percent', id, percentValue);
      } else {
        emit('update-category-percent', id, value);
      }

      categoryToEdit.value = null;
    };

    const handleAddProductToCategory = (categoryId) => emit('add-product', categoryId);

    return {
      t,
      categoryToEdit,
      editProduct,
      handleCategoryPercentReset,
      handleCategoryPercentEdit,
      handleAddProductToCategory,
      faPen,
      faPercent,
      faPlus,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/mixins.scss';

h2,
h3,
h4 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  > svg {
    font-size: 1.6rem;
    text-align: right;
    cursor: pointer;
  }
}

.category-header {
  padding: 2rem 0 1rem 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__title {
    margin: 0;
  }

  &__plus {
    margin-left: auto;
  }
}

.child-categories {
  margin-left: 0.5rem;

  @include ipad {
    margin-left: 1rem;
  }
}

.child-child-categories {
  margin-left: 1rem;

  @include ipad {
    margin-left: 2rem;
  }
}
</style>

<style lang="scss" module>
@import '@/assets/styles/common/variables.scss';

.percentButton {
  padding: 0.2rem 2rem;
  font-size: 1.6rem;

  margin-left: 2rem;
  min-height: auto;

  background-color: $premium;
}
</style>
