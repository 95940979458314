const filterCategories = (categories) => {
  const filtered = categories.filter((c) => c.productsCount);

  return filtered.map((c) => ({
    ...c,
    childCategories: c.childCategories ? filterCategories(c.childCategories) : [],
  }));
};

export default {
  filterCategories,
};
