import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import lowcoApi from '@/api/lowco-api';
import productCatalogUtils from '@/utils/productCatalog.utils';
import useCurrentCompany from './useCurrentCompany';

const useProductCatalog = () => {
  const toast = useToast();
  const { t } = useI18n();

  const { currentCompanyId } = useCurrentCompany();

  const loading = ref(false);
  const products = ref([]);
  const categories = ref([]);
  const productViews = ref([]);

  const productsViewsObject = computed(() =>
    productViews.value.length
      ? productViews.value.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.viewCount }), {})
      : [],
  );

  const productsWithViews = computed(() =>
    products.value.map((p) => ({ ...p, views: productsViewsObject.value?.[p.id] || null })),
  );

  const setProducts = (callback) => {
    products.value = callback(products.value);
  };

  const mapCategory = (category) => ({
    ...category,
    products: productsWithViews.value.filter((i) => i.categoryId === category.id),
  });

  const mapCat = (category) => {
    const c = mapCategory(category);
    c.productsCount = c.products.length;

    if (c.childCategories?.length) {
      c.childCategories = c.childCategories.map(mapCat);
      c.productsCount += c.childCategories.reduce((acc, curr) => acc + curr.productsCount, 0);
    }

    return c;
  };

  const computedCategories = computed(() =>
    [
      mapCategory({ name: t('pages.catalog.noCategory'), childCategories: [], readonly: true }),
      ...categories.value,
    ].map(mapCat),
  );

  const computedFilteredCategories = computed(() =>
    productCatalogUtils.filterCategories(computedCategories.value),
  );

  const extractCategoriesPercentValues = (cats) =>
    cats.reduce((acc, curr) => {
      if (!curr.childCategories?.length) {
        return {
          ...acc,
          [curr.id]: curr.percentValue,
        };
      }

      return {
        ...acc,
        [curr.id]: curr.percentValue,
        ...extractCategoriesPercentValues(curr.childCategories),
      };
    }, {});

  const categoriesPercentValues = computed(() => extractCategoriesPercentValues(categories.value));

  const loadData = async (callback) => {
    try {
      const fetchedCategories = await lowcoApi.getCompanyProductCategories(currentCompanyId.value);
      const fetchedCatalog = await lowcoApi.getProducts(currentCompanyId.value);

      products.value = fetchedCatalog.products.data;
      categories.value = fetchedCategories;

      callback(fetchedCategories, fetchedCatalog);
    } catch (err) {
      const [error] = err;

      toast.error(t(error));
    }
  };

  const loadProductsViews = async () => {
    try {
      const result = await lowcoApi.getProductsViews(currentCompanyId.value);

      if (!result) {
        productViews.value = [];
        return;
      }

      productViews.value = result;
    } catch (err) {
      productViews.value = [];
    }
  };

  return {
    loading,
    categories,
    computedFilteredCategories,
    productsWithViews,
    categoriesPercentValues,
    setProducts,
    loadData,
    loadProductsViews,
  };
};

export default useProductCatalog;
