<template>
  <Modal
    :contentClassname="$style.modalContent"
    :isDisplayed="visible"
    @close-modal="$emit('close')"
  >
    <AccountCreationLoader :loading="!companyDataFetchingError" />
  </Modal>
</template>

<script>
import { defineComponent, ref, toRefs, watch } from 'vue';

import AccountCreationLoader from '@/components/productSaleSetup/AccountCreationLoader.vue';
import heurecaService from '@/services/heureca.service';
import useCurrentCompany from '@/composables/useCurrentCompany';
import Modal from '@/components/common/Modal.vue';

const ProductsSaleSetupModal = defineComponent({
  components: {
    AccountCreationLoader,
    Modal,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'created'],
  setup(props, { emit }) {
    const { visible } = toRefs(props);

    const { currentCompanyId } = useCurrentCompany();

    const companyData = ref(null);
    const companyDataFetchingError = ref(false);

    const receivedTokenKey = ref(null);

    const setAsyncTimeout = (cb, timeout = 300) =>
      new Promise((resolve) => {
        setTimeout(() => {
          cb();
          resolve();
        }, timeout);
      });

    const createHeurecaAccount = async () => {
      try {
        const { tokenKey, companyId } = await heurecaService.createAccount(currentCompanyId.value);

        if (!companyId) {
          receivedTokenKey.value = tokenKey;

          return false;
        }

        emit('created', { tokenKey, companyId });

        return true;
      } catch {
        // Ignore for now
        return false;
      }

    };

    const fetchCompanyData = async () => {
      if (companyData.value) {
        return false;
      }

      try {
        const result = await heurecaService.fetchCompany(currentCompanyId.value);

        companyData.value = result;
      } catch {
        // Ignore for now
      }

      return true;
    };

    const waitForCreationToComplete = () => {
      const timesToWait = [300, 400, 600, 800, 1000, 1000, 1000];
      const totalTimeToWait = timesToWait.reduce((acc, curr) => acc + curr, 0);

      let oldTtw = 0;
      timesToWait.every(async (ttw) => {
        const currentTtw = oldTtw + ttw;
        oldTtw += ttw;

        const shouldContinue = await setAsyncTimeout(fetchCompanyData, currentTtw);

        return shouldContinue;
      });

      setTimeout(() => {
        if (!companyData.value) {
          companyDataFetchingError.value = true;
        }
      }, totalTimeToWait);
    };

    const handleAccountCreation = async () => {
      const result = await createHeurecaAccount();

      if (!result) {
        waitForCreationToComplete();
      }
    };

    watch(
      visible,
      (value) => {
        if (!value) {
          return;
        }

        handleAccountCreation();
      },
      { immediate: true },
    );

    watch(companyData, (value) => {
      if (!value) {
        return;
      }

      emit('created', { tokenKey: receivedTokenKey.value, userId: value.userId });
    });
  },
});

export default ProductsSaleSetupModal;
</script>

<style lang="scss" module>
.modalContent {
  padding: 2rem 4rem;
}
</style>
