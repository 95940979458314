import { computed, ref } from 'vue';

const useProductPercentage = (productPercentage, categoryPercentage) => {
  const isFieldDirty = ref(false);
  const resetPercentage = ref(false);
  const noPercentage = ref(false);

  const finalPercentage = computed(() => {
    if (resetPercentage.value) {
      return categoryPercentage.value;
    }

    if (noPercentage.value) {
      return 0;
    }

    return isFieldDirty.value ? productPercentage.value : null;
  });

  const mustSetPercentValueToNull = computed(() => finalPercentage.value === categoryPercentage.value);

  const setFieldDirty = (value) => {
    isFieldDirty.value = value;

    resetPercentage.value = false;
    noPercentage.value = false;
  };

  const setResetPercentage = (value) => {
    resetPercentage.value = value;

    isFieldDirty.value = false;
    noPercentage.value = false;
  };

  const setNoPercentage = (value) => {
    noPercentage.value = value;

    isFieldDirty.value = false;
    resetPercentage.value = false;
  };

  return {
    finalPercentage,
    mustSetPercentValueToNull,
    setFieldDirty,
    setResetPercentage,
    setNoPercentage,
  };
};

export default useProductPercentage;
