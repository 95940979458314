<template>
  <article :class="[$style.product, { [$style.last]: last }]">
    <div>
      <h5>{{ product.name }}</h5>
      <div
        v-if="product.views"
        :class="$style.views"
      >
        {{ t('components.product.views', [product.views]) }}
      </div>
    </div>
    <div :class="$style.infos">
      <button v-if="productPrice" :class="$style.infoPill" @click="editProduct(product.id)">
        {{ productPrice }}
      </button>
      <button
        v-if="usesPremium && productPercentValue"
        :class="[$style.infoPill, $style.promo]"
        @click="editProduct(product.id)"
      >
        {{ productPromoPrice }}
      </button>
      <button
        v-if="usesPremium"
        :class="[$style.infoPill, $style.promo]"
        @click="editProduct(product.id)"
      >
        {{ productPercentValue || '-' }} %
      </button>
    </div>
    <div :class="$style.actions">
      <FontAwesomeIcon :icon="faPen" @click="editProduct(product.id)" />
    </div>
  </article>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import Big from 'big.js';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Product',
  components: {
    FontAwesomeIcon,
  },
  props: {
    product: Object,
    last: Boolean,
    percentValue: {
      type: Number,
      required: false,
    },
    usesPremium: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { product, percentValue } = toRefs(props);

    const { t } = useI18n();

    const productPrice = computed(() => {
      if (product.value?.price == null) {
        return '';
      }

      const stringPrice = `${product.value.price}`;
      return `${stringPrice.replace('.', ',')} €`;
    });

    const productPercentValue = computed(() => product.value.percentValue ?? percentValue.value);

    const promoPrice = computed(() => {
      const { price } = product.value;

      if (!productPercentValue.value || !price) {
        return price;
      }

      const decimalPercentValue = new Big(productPercentValue.value).div(100).toNumber();
      const promotion = new Big(price).times(decimalPercentValue).toNumber();

      return new Big(price).minus(promotion).toFixed(2);
    });

    const productPromoPrice = computed(() => {
      if (promoPrice.value == null) {
        return '';
      }

      const stringifiedPrice = `${promoPrice.value}`;
      return `${stringifiedPrice.replace('.', ',')} €`
    })

    const editProduct = (id) => emit('edit-product', id);

    return {
      t,
      productPrice,
      productPromoPrice,
      productPercentValue,
      promoPrice,
      editProduct,
      faEye,
      faPen,
    };
  },
});
</script>

<style lang="scss" module>
@import '../../assets/styles/common/variables.scss';
@import '../../assets/styles/common/mixins.scss';

.product {
  background-color: $alternate-green;
  border-top: 1px solid $dark-green;
  display: flex;
  padding: 1rem;
  justify-content: space-between;

  &.last {
    border-bottom: 1px solid $dark-green;
  }

  .actions {
    display: flex;

    > * {
      align-self: center;
      cursor: pointer;
    }
  }

  .views {
    margin-top: 1rem;

    font-size: 1.4rem;

    @include ipad {
      font-size: 1.6rem;

      .viewsIcon {
        font-size: 1.6rem;
      }
    }

    .viewsIcon,
    .viewsCount {
      color: $alternate-green;
    }

    .viewsIcon {
      font-size: 1.2rem;
    }
  }

  .infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

    margin-left: 2rem;
    margin-right: 2rem;

    @include iphone-6 {
      margin-left: auto;
    }

    @include ipad {
      margin-right: 3rem;
    }

    .infoPill {
      margin: 0.2rem;
      padding: 0.2rem 1rem;

      font-size: 1.4rem;
      border-radius: 30px;

      background-color: $dark-green;
      color: $white;

      @include xs {
        font-size: 1.6rem;
        padding: 0.2rem 2rem;
      }

      &.promo {
        background-color: $premium;
      }
    }
  }
}
</style>
