<template>
  <div :class="$style.wrapper">
    <template v-if="loading">
      <FontAwesomeIcon :class="$style.loader" :icon="faSpinnerThird" />
      <h3 class="mt-5">{{ t('components.accountCreationLoader.message') }}</h3>
      <p class="mt-1">{{ t('components.accountCreationLoader.loading') }}</p>
    </template>
    <template v-if="!loading">
      <p>{{ t('components.accountCreationLoader.error') }}</p>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from 'vue-i18n';

const AccountCreationLoader = defineComponent({
  components: { FontAwesomeIcon },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      faSpinnerThird,
    };
  },
});

export default AccountCreationLoader;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  font-size: 10rem;

  @include spin(1.2s);
}
</style>
