import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const createAccount = (companyId) => httpService
  .post(`${BASE_URL}/heureca/create-account?companyId=${companyId}`)
  .catch(throwError);

const fetchCompany = (companyId) => httpService
  .get(`${BASE_URL}/heureca/companies/${companyId}`)
  .catch(throwError);

const logIntoHeureca = (companyId) => httpService
  .post(`${BASE_URL}/heureca/login`, { companyId })
  .catch(throwError);
  
export default {
  createAccount,
  fetchCompany,
  logIntoHeureca,
};
