<template>
  <iframe :class="$style.heurecaFrame" :src="heurecaAdminUrl" frameborder="0"></iframe>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, toRefs } from 'vue';

import config from '@/config';

const HeurecaAdminFrame = defineComponent({
  components: {},
  props: {
    heurecaCredentials: {
      type: Object,
      required: true,
    },
    showOpenings: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { heurecaCredentials, showOpenings } = toRefs(props);

    const heurecaAdminUrl = computed(() => {
      const { companyId, tokenKey } = heurecaCredentials.value;

      const baseUrl = `${config.heurecaAdminUrl}?companyId=${companyId}&tokenKey=${tokenKey}`;

      if (showOpenings.value) {
        return `${baseUrl}&pageNavigation=planning`;
      }

      return baseUrl;
    });

    const setAppOverflow = (overflowValue) => {
      const el = document.getElementById('app');

      el.style.overflow = overflowValue;
    }

    onMounted(() => {
      setAppOverflow('hidden');
    });

    onUnmounted(() => {
      setAppOverflow('');
    });

    return {
      heurecaAdminUrl,
    };
  },
});

export default HeurecaAdminFrame;
</script>

<style lang="scss" module>
.heurecaFrame {
  width: 100%;
  height: 100%;
}
</style>
