<template>
  <Layout
    :loading="isLoading || isHeurecaLoading"
    :containerClassname="{ [$style.heurecaContainer]: heurecaCredentials }"
    :hideHeader="!!heurecaCredentials"
  >
    <template v-if="!heurecaCredentials">
      <LinkButton
        class="tac mb-2"
        link-like-underlined
        :to="`${routesConstants.OFFERS}?type=${companyOffersConstants.ONLINE_SHOP}`"
      >
        {{ t('pages.catalog.useOnlineShopLink') }}
      </LinkButton>
    
      <ProductsSaleSetupModal
        :visible="showHeurecaModal"
        @close="showHeurecaModal = false"
        @created="handleHeurecaAccountCreation"
      />

      <div class="top">
        <p class="tac">
          {{ t('pages.catalog.topInstructions') }}
        </p>

        <div class="blank-area" />
        <div class="button">
          <Button
            :class="$style.buttonWrapper"
            :buttonClass="$style.buttonItem"
            :text="t('pages.catalog.addProductButton')"
            @buttonClick="isDisplayedProduct = true"
            extraSmall
          />
        </div>

        <div v-if="companyUsesPremium" class="lowco-textbox lowco-textbox-info">
          <p
            class="offer-description"
            v-html="t('pages.catalog.usePremium', [catalogPercentValue])"
          />
          <router-link class="link offer-link" :to="routesConstants.OFFERS">
            {{ t('pages.catalog.updateOffer') }}
          </router-link>
        </div>
      </div>

      <AddProductModal
        :categories="categories"
        :editProduct="product"
        :companyId="companyId"
        :isDisplayed="isDisplayedProduct"
        :usesPremium="companyUsesPremium"
        :productCategory="productCategory"
        :categoriesPercentValues="categoriesPercentValues"
        @close-modal-product="handleProductAdd"
        @delete="deleteProduct"
        @on-media-delete="onProductMediaDelete"
      />

      <Categories
        v-if="computedFilteredCategories"
        :categories="computedFilteredCategories"
        :usesPremium="companyUsesPremium"
        @edit-product="editProduct"
        @update-category-percent="handleCategoryEdit"
        @add-product="handleAddProductFromCategory"
      />
    </template>

    <HeurecaAdminFrame
      v-if="heurecaCredentials"
      :heurecaCredentials="heurecaCredentials"
      :showOpenings="showOpenings"
    />
  </Layout>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Layout from '@/components/common/Layout.vue';
import Button from '@/components/common/Button.vue';
import LinkButton from '@/components/common/LinkButton.vue';
import AddProductModal from '@/components/catalogue/AddProductModal.vue';
import Categories from '@/components/catalogue/Categories.vue';
import HeurecaAdminFrame from '@/components/heureca/HeurecaAdminFrame.vue';
import routesConstants from '@/constants/routes.constants';
import companyOffersConstants from '@/constants/companyOffers.constants';
import lowcoApi from '@/api/lowco-api';
import heurecaService from '@/services/heureca.service';
import useCurrentCompany from '@/composables/useCurrentCompany';
import useProductCatalog from '@/composables/useProductCatalog';
import useCurrentCompanyDetails from '@/composables/useCurrentCompanyDetails';

import ProductsSaleSetupModal from '../components/productSaleSetup/ProductsSaleSetupModal.vue';

export default defineComponent({
  name: 'Catalog',
  components: {
    Layout,
    Button,
    LinkButton,
    AddProductModal,
    Categories,
    ProductsSaleSetupModal,
    HeurecaAdminFrame,
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const route = useRoute();

    const { currentCompanyId } = useCurrentCompany();
    const { currentCompany } = useCurrentCompanyDetails();
    const {
      loading,
      categories,
      computedFilteredCategories,
      productsWithViews,
      categoriesPercentValues,
      setProducts,
      loadData,
      loadProductsViews,
    } = useProductCatalog(currentCompanyId);

    const isHeurecaLoading = ref(false);
    const showHeurecaModal = ref(route.query?.['online-shop'] === 'true');
    const showOpenings = ref(route.query?.['show-openings'] === 'true');

    return {
      t,
      toast,
      routesConstants,
      companyOffersConstants,
      company: currentCompany,
      companyId: currentCompanyId,
      isLoading: loading,
      categories,
      computedFilteredCategories,
      productsWithViews,
      categoriesPercentValues,
      isHeurecaLoading,
      setProducts,
      loadData,
      loadProductsViews,
      showHeurecaModal,
      showOpenings,
    };
  },
  data() {
    return {
      isDisplayedProduct: false,
      product: null,
      productCategory: null,
      catalogPercentValue: null,
      companyUsesPremium: false,
      heurecaCredentials: null,
    };
  },
  computed: {
    canSubmit() {
      const { hasDeliveryService, hasTakeAwayService } = this.company;

      if (hasDeliveryService || hasTakeAwayService) {
        return !!this.company.clickAndCollectEmail?.trim();
      }

      return true;
    },
  },
  watch: {
    async company(value) {
      if (!value.heurecaCompanyId) {
        this.loadData(this.afterLoadData);
        this.loadProductsViews();

        return;
      }

      this.logIntoHeureca();
    },
  },
  methods: {
    afterLoadData(fetchedCategories, fetchedCatalog) {
      this.companyUsesPremium = fetchedCatalog.companyUsesPremium;
      if (fetchedCatalog.companyUsesPremium) {
        this.catalogPercentValue = fetchedCatalog.defaultPercentValue;
      }
    },
    // Categories related
    handleCategoryEdit(categoryId, percentValue) {
      if (percentValue === undefined || percentValue === null) {
        this.removeCategoryPercentValue(categoryId);

        return;
      }

      this.updateCategoryPercentValue(categoryId, percentValue);
    },
    async updateCategoryPercentValue(categoryId, percentValue) {
      try {
        await lowcoApi.editCompanyProductCategoryPercent(this.companyId, categoryId, percentValue);

        this.loadData();
      } catch (err) {
        const [error] = err;

        this.toast.error(this.t(error));
      }
    },
    async removeCategoryPercentValue(categoryId) {
      try {
        await lowcoApi.removeCompanyProductCategoryPercent(this.companyId, categoryId);

        this.loadData();
      } catch (err) {
        const [error] = err;

        this.toast.error(this.t(error));
      }
    },
    closeModalProduct() {
      this.handleProductAdd(null);
    },
    // Products related
    handleAddProductFromCategory(categoryId) {
      this.productCategory = categoryId;
      this.isDisplayedProduct = true;
    },
    handleProductAdd(product) {
      const isNew = !this.product;
      this.product = null;
      this.productCategory = null;
      this.isDisplayedProduct = false;

      if (!product) {
        return;
      }

      if (isNew) {
        this.setProducts((products) => [...products, product]);
      } else {
        this.setProducts((products) => products.map((p) => (p.id !== product.id ? p : product)));
      }
    },
    editProduct(productId) {
      this.product = this.productsWithViews.find((i) => i.id === productId);
      this.isDisplayedProduct = true;
    },
    async deleteProduct(productId) {
      try {
        await lowcoApi.deleteProduct(productId);

        this.closeModalProduct();

        this.setProducts((products) => products.filter((p) => p.id !== productId));
      } catch (err) {
        const [error] = err;

        this.toast.error(this.t(error));
      }
    },
    // Product media related
    async onProductMediaDelete(productId, mediaId) {
      try {
        await lowcoApi.removeProductMedia(productId, mediaId);

        this.product = {
          ...this.product,
          medias: this.product.medias.filter((m) => m.id !== mediaId),
        };
      } catch (err) {
        const [error] = err;

        this.toast.error(this.t(error));
      }
    },
    // Heureca related
    handleHeurecaAccountCreation(data) {
      const { tokenKey, companyId } = data;

      this.showOpenings = true;
      this.heurecaCredentials = { tokenKey, companyId };

      this.showHeurecaModal = false;
      this.$router.replace(this.$route.path);
    },
    async logIntoHeureca() {
      try {
        this.isHeurecaLoading = true;
        const result = await heurecaService.logIntoHeureca(this.companyId);

        this.heurecaCredentials = result;
      } catch {
        // Ignore for now
      } finally {
        this.isHeurecaLoading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables.scss';
@import '@/assets/styles/common/mixins.scss';

.is-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.offer-description {
  color: #fff;
}

.offer-link {
  color: #fff;
  box-shadow: inset 0 -2px #fff;
}

.top {
  @include spacing-children('vertical', 1rem);

  .blank-area {
    position: fixed;
    z-index: 99999;
    inset: auto 0 calc(6rem + env(safe-area-inset-bottom)) 0;
    background-color: #fff;
    height: 6rem;
    box-shadow: 0 -2px 10px rgba(#000, 0.15);
  }

  .button {
    z-index: 100000;
    position: fixed;
    bottom: 7rem;
    bottom: calc(7rem + env(safe-area-inset-bottom));
    left: calc(2rem + env(safe-area-inset-left));
    right: calc(2rem + env(safe-area-inset-right));
  }

  @include ipad {
    .blank-area {
      display: none;
    }
    .button {
      position: static;
      display: flex;
      justify-content: center;
    }
  }
}

.save-button {
  width: auto;
}

.buttons {
  text-align: right;

  > div {
    display: inline-block;
    max-width: 330px;
    margin: 10px;
  }
}

.link.open-heureca-modal-button {
  display: inline-block;
  font-weight: normal;
  color: #fff;
  box-shadow: inset 0 -2px #fff;
  padding: 0;
}
</style>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.heurecaContainer {
  max-width: none;
  width: 100%;
  margin: 0 auto;
  padding: 0 !important;
}

.buttonWrapper,
.buttonItem {
  width: 100%;

  @include ipad {
    width: auto;
  }
}
</style>
