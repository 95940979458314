<template>
  <Modal
    :isDisplayed="!!modelValue && usesPremium"
    :contentClassname="$style.modalContent"
    @close-modal="$emit('update:modelValue', null)"
  >
    <h2 class="mb-2">{{ t('components.editCategoryModal.modalTitle') }}</h2>
    <p>{{ t('components.editCategoryModal.specificPercentValue') }}</p>

    <InputNumberSlider
      class="mt-2"
      :modelValue="modelValue.percentValue"
      @update:modelValue="$emit('update:modelValue', { ...modelValue, percentValue: $event })"
    />

    <div :class="$style.percentModalButtons">
      <Button
        :class="$style.fullWidth"
        :buttonClass="$style.percentModalButton"
        @buttonClick="() => handleCategoryPercentEdit()"
      >
        {{ t('common.confirm') }}
      </Button>

      <Button
        :class="$style.halfWidth"
        :buttonClass="[$style.percentModalButton, $style.noPercentageButton]"
        @buttonClick="handleCategoryPercentReset"
      >
        {{ t('common.percentages.setDefaultPercentageButton') }}
      </Button>

      <Button
        :class="$style.halfWidth"
        :buttonClass="[$style.percentModalButton, $style.noPercentageButton]"
        @buttonClick="() => handleCategoryPercentEdit(0)"
      >
        {{ t('common.percentages.noPercentageButton') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import InputNumberSlider from '@/components/common/InputNumberSlider.vue';

const EditCategoryModal = defineComponent({
  components: {
    Modal,
    Button,
    InputNumberSlider,
  },
  props: {
    modelValue: {
      type: [Object, null],
      required: true,
    },
    usesPremium: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'update', 'reset', 'update:modelValue'],
  setup(_, { emit }) {
    const { t } = useI18n();

    const handleCategoryPercentEdit = (value) => emit('update', value);
    const handleCategoryPercentReset = () => emit('reset');

    return {
      t,
      handleCategoryPercentEdit,
      handleCategoryPercentReset,
    };
  },
});

export default EditCategoryModal;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables.scss';
@import '@/assets/styles/common/mixins.scss';
.modalContent {
  min-width: 35rem;

  @include xs {
    min-width: 50rem;
  }
}

.percentButton {
  padding: 0.2rem 2rem;
  font-size: 1.6rem;

  margin-left: 2rem;
  min-height: auto;

  background-color: $premium;
}

.percentModalButtons {
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  .percentModalButton {
    width: 100%;
  }

  .fullWidth {
    grid-column: span 2;
  }

  .halfWidth {
    grid-column: span 1;
  }
}

.noPercentageButton {
  font-size: 1.6rem;
  font-weight: normal;
}
</style>
